import LocalImage from '@components/local-images'
import '@scss/gatstrap.scss'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Helmet from 'react-helmet'

// markup
const HomePage = ({ data, filename }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description
  const siteKeywords = data.site.siteMetadata.keywords

  return (
    <div className="wrapper">
      <Helmet
        htmlAttributes={{
          lang: 'ja',
        }}
        title={siteTitle}
        meta={[
          { name: 'description', content: `${siteDescription}` },
          { name: 'keyword', content: `${siteKeywords}` },
          {
            name: 'google-site-verification',
            content: `ugkUAI9NnQzJA50p7F56K3Zz2vg6Ls93ZXftAZZlG_Y`,
          },
        ]}
      >
        <link
          href="/images/favicon.ico"
          rel="shortcut icon"
          type="image/x-icon"
        />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-YWXRDLV7BN"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-YWXRDLV7BN');
          `}
        </script>
      </Helmet>
      <header className="bg-dark">
        <div className="d-flex justify-content-center align-content-center py-3">
          <LocalImage
            src="logo.svg"
            alt="ハイローオーストラリア"
            className="header-logo"
          />
        </div>
      </header>
      <main>
        <Container>
          <Row>
            <Col sm={12} md={5} className="mx-auto">
              <div className="modal-card">
                <div className="modal-card-body">
                  <h2 className="title">ハイローデモ取引</h2>
                  <a
                    className="login"
                    href="https://highlow.com/account?a_aid=5d74a21062504"
                  >
                    デモ開始はこちらから
                  </a>
                </div>
                <p className="new-register">
                  <a href="https://highlow.com/account?a_aid=5d74a21062504">
                    ログイン・新規開設はこちら
                  </a>
                </p>
                <div className="news">
                  <p>
                    <span className="news-title">
                      新しく口座開設をされた方には
                    </span>
                    <b>
                      <span className="news-text">
                        <a
                          className="color-fccd2a"
                          href="https://highlow.com/account?a_aid=5d74a21062504"
                        >
                          5,000円
                        </a>
                        のキャッシュバック還元をお受け取り頂けます
                      </span>
                    </b>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
      <footer>
        <p>© HLMI Ltd | All Rights Reserved</p>
      </footer>
    </div>
  )
}

export default HomePage

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
    allFile {
      edges {
        node {
          relativePath
          name
        }
      }
    }
  }
`
