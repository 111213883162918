import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

export default ({ style, alt, src, className }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            publicURL
            extension
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const image = data.images.edges.find(img =>
    img.node.relativePath.includes(src)
  )
  if (!image) return null

  if (!image.node.childImageSharp && image.node.extension === 'svg') {
    return <img src={image.node.publicURL} alt={alt} />
  } else {
    return (
      <Img
        fluid={image.node.childImageSharp.fluid}
        alt={alt}
        style={style}
        className={className}
      />
    )
  }
}
